import React from "react";
import banner from "../images/GODCHI BANNER UPDATED.png";

const About = () => {
  return (
    <div className='w-screen h-screen flex justify-center items-center '>
      {/* <div className='flex justify-center gap-16 items-center '>
        <div className='overflow-hidden rounded-lg w-1/4 h-auto'>
          <img
            src={aboutHero}
            alt='about-hero'
            className='w-full h-full object-cover overflow-hidden border-8 border-lime-400'
          />
        </div>
        <div className='w-1/4 self-start text-white text-2xl font-bold'>
          <p>
            Godchi is a Lorem ipsum dolor sit, amet consectetur adipisicing
            elit. Minus tempora numquam eaque, quisquam rem maxime ducimus
            voluptas veniam non, nisi temporibus tempore expedita maiores atque,
            ipsam laboriosam voluptatibus! Cumque, numquam!
          </p>
        </div>
      </div> */}
      <div className='h-5/6 relative'>
        <img
          src={banner}
          alt='banner'
          className='w-full h-full object-cover opacity-50'
        />
        <p className='text-center font-semibold absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-xl sm:text-lg w-5/6'>
          In the beginning God created $GODCHI. 2 Now the $GODCHI was formless
          and empty, darkness was over the surface of the deep, and the Spirit
          of God was hovering over the blockchain. 3 And God said, “Let there be
          liquidity,” and there was liquidity. 4 God saw that the liquidity was
          good, and he separated the liquidity from the ruggers. 5 God called
          the token as “$GODCHI,” and the darkness he called “shitcoin” 6 And
          God said, “Let there be a token between the blockchain to separate
          snipers from dumping it.” 7 So God made $GODCHI and separated from the
          snipers by buying it and burning it. And it was so. 8 God called the
          token “$GODCHI”
        </p>
      </div>
    </div>
  );
};

export default About;
