import Navbar from "./components/Navbar";
import About from "./sections/About";
import Home from "./sections/Home";
import mainBg from "./images/main-bg.png";
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <div
        style={{ backgroundImage: `url(${mainBg})` }}
        className='-z-50 w-screen h-screen fixed top-0 bg-cover bg-center'
      ></div>
      <Navbar />
      <Home />
      <div className=' bg-amber-600/90 w-screen'>
        <About />
        <Footer />
      </div>
    </div>
  );
}

export default App;
