import React from "react";
import x from "../images/x.svg";
import telegram from "../images/telegram.png";
import pump1 from "../images/PUMPFUN PNG.png";

const Footer = () => {
  return (
    <footer className='w-full flex justify-center text-white items-center gap-20 mt-8 pb-20 sm:gap-12'>
      <a href='https://twitter.com/GodchiSol' target='_blank'>
        <div className='text-2xl font-bold text-center hover:bg-lime-400/70 cursor-pointer p-5 sm:p-3 rounded-lg sm:text-lg'>
          <div className='w-32 sm:w-20'>
            <img src={x} alt='x' className='object-cover w-full h-full' />
          </div>
          X
        </div>
      </a>
      <a
        href='https://pump.fun/6dpFbcUk9pegzBTTgnTimQLmCTqYUqGdRx521XhddPt2'
        target='_blank'
      >
        <div className='text-2xl sm:text-lg font-bold text-center hover:bg-lime-400/70 cursor-pointer p-5 sm:p-3 px-8 sm:px-6 rounded-lg flex flex-col items-center gap-1'>
          <div className='w-24 sm:w-16'>
            <img
              src={pump1}
              alt='pumpfun'
              className='object-cover w-full h-full'
            />
          </div>
          Pump.fun
        </div>
      </a>
      <a href='https://t.me/godchisol' target='_blank'>
        <div className='text-2xl sm:text-lg font-bold text-center hover:bg-lime-400/70 cursor-pointer p-5 sm:p-3 px-8 sm:px-6 rounded-lg flex flex-col items-center gap-3'>
          <div className='w-20 sm:w-16'>
            <img
              src={telegram}
              alt='telegram'
              className='object-cover w-full h-full translate-y-1'
            />
          </div>
          <p>Telegram</p>
        </div>
      </a>
    </footer>
  );
};

export default Footer;
