import React from "react";

const Navbar = () => {
  return (
    <nav className=' bg-transparent px-6 py-5 text-white fixed top-0 w-full z-50'>
      <h1 className='press-start-2p-regular text-2xl'>$GODCHI</h1>
    </nav>
  );
};

export default Navbar;
