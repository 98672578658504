import React from "react";
import hero from "../images/GODCHI FULL BODY IMAGE 2.png";
import MainButton from "../components/MainButton";

const Home = () => {
  return (
    <div
      className='w-screen h-screen bg-cover flex-col flex justify-center items-center'
      // style={{ backgroundImage: `url(${mainBg})` }}
    >
      <a
        href='https://pump.fun/6dpFbcUk9pegzBTTgnTimQLmCTqYUqGdRx521XhddPt2'
        target='_blank'
      >
        <div className='w-96 relative translate-y-5 hover:scale-105 transition-all focus:scale-95 cursor-pointer sm:w-72'>
          <img
            src={hero}
            alt='hero-img'
            className='w-full h-full object-cover -translate-y-16'
          />
          <div className='absolute top-1/2 left-1/2 -translate-x-1/2 translate-y-20'>
            <MainButton />
          </div>
        </div>
      </a>
      <p className='font-bold text-amber-900 -mt-8 text-2xl text-center sm:mt-1'>
        <p>Fuck Sniper bots</p>
        <p>69% Dev supply burns at $50k marketcap</p>
      </p>
    </div>
  );
};

export default Home;
