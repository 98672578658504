import React from "react";

const MainButton = () => {
  return (
    <button className='bg-lime-400 sm:text-xl text-4xl border-lime-800 border-8 p-6 sm:p-4 px-10 sm:px-8 press-start-2p-regular whitespace-nowrap text-center'>
      Buy $GODCHI
    </button>
  );
};

export default MainButton;
